import { computed, inject, Injectable, signal } from '@angular/core';
import {
  IUpdateUserSettingsBody,
  IUserSettings,
  UserSettingsApiService,
} from '../api/user-settings-api.service';
import { catchError, filter, of, Subject, switchMap } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { AuthUserService } from '../../../../../@auth/services/auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private apiService = inject(UserSettingsApiService);
  public authUserService = inject(AuthUserService);

  public userSettingsState = signal<IUserSettingsState>({
    userSettings: [],
    loaded: false,
  });

  public userSettings = computed(() => {
    return this.userSettingsState().userSettings;
  });

  public userSettings$ = toObservable(this.userSettings);

  public loaded = computed(() => {
    return this.userSettingsState().loaded;
  });

  private settingsSource = toObservable(this.authUserService.user);
  private settings$ = this.settingsSource.pipe(
    filter((userEchoId) => !!userEchoId),
    switchMap((userEchoId) => {
      return this.apiService
        .getUserSettings(userEchoId?.userId || '')
        .pipe(catchError(() => of(null)));
    }),
  );

  private updateSettingsSource = new Subject<IUpdateUserSettingsBody>();
  private updateSettings$ = this.updateSettingsSource.pipe(
    switchMap((body) => {
      const toUpdate = this.userSettings().find((x) => x.key === body.key);

      return this.apiService
        .updateUserSettings({
          ...body,
          data: {
            ...toUpdate?.data,
            ...body.data,
          },
        })
        .pipe(catchError(() => of(null)));
    }),
  );

  public updateUserSetting(body: IUpdateUserSettingsBody) {
    this.updateSettingsSource.next(body);
  }

  constructor() {
    this.settingsSource.pipe(takeUntilDestroyed()).subscribe(() => {
      this.userSettingsState.update((state) => {
        return { ...state, loaded: false };
      });
    });

    this.settings$.pipe(takeUntilDestroyed()).subscribe((userSettings) => {
      this.userSettingsState.update((state) => {
        return {
          ...state,
          userSettings: userSettings || [],
          loaded: true,
        };
      });
    });

    this.updateSettings$.pipe(takeUntilDestroyed()).subscribe((setting) => {
      if (!setting) return;

      this.userSettingsState.update((state) => {
        return {
          ...state,
          userSettings: [
            ...state.userSettings.filter((x) => x.key !== setting.key),
            setting,
          ],
        };
      });
    });
  }
}

export interface IUserSettingsState {
  userSettings: IUserSettings[];
  loaded: boolean;
}

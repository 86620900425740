import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthUserService } from '../services/auth-user.service';
import { Injectable } from '@angular/core';
import { UserSettingsService } from '../../cms/shared/utils/services/logic/user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authUserService: AuthUserService,
    private settingsService: UserSettingsService,
    private router: Router,
  ) {}

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    next: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authUserService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
    }
    return false;
  }
}

import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { User } from './auth.model';
import {
  AuthService,
  IAuthenticateData,
  IChangePasswordAdminData,
  IChangePasswordData,
} from './api/auth.service';
import {
  catchError,
  delay,
  filter,
  merge,
  of,
  share,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

export const TOKEN_KEY = 'cms_auth_token';

export interface AuthUserState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  processing?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private authService = inject(AuthService);
  private router = inject(Router);
  private toastrService = inject(ToastrService);

  private authUserState = signal<AuthUserState>({
    user: null,
    token: null,
    isAuthenticated: false,
    processing: false,
  });

  public isAuthenticated = computed(() => {
    return this.authUserState().isAuthenticated;
  });

  public user = computed(() => {
    return this.authUserState().user;
  });

  public token = computed(() => {
    return this.authUserState().token;
  });

  public processing = computed(() => {
    return this.authUserState().processing;
  });

  public closePasswordDialog = new Subject<void>();

  isAdministrator = computed(() => {
    const user = this.user();

    if (user === null) return false;

    return user.userType.internalName === 'Administrator';
  });

  private authenticateSource = new Subject<IAuthenticateData>();
  private authenticateToken = new Subject<string>();
  private authenticate$ = this.authenticateSource.pipe(
    switchMap((data) => {
      return this.authService.authenticate(data).pipe(
        catchError(() => {
          this.toastrService.error('Invalid email or password');
          return of(null);
        }),
      );
    }),
    share(),
  );

  _changePasswordAdmin = new Subject<IChangePasswordAdminData>();

  private changePasswordAdminSource$ = this._changePasswordAdmin.pipe(
    switchMap((data) => {
      return this.authService
        .changePasswordAdmin(data)
        .pipe(catchError(() => of(null)));
    }),
    filter((x) => x !== null),
    tap(() => {
      this.closePasswordDialog.next();
    }),
  );

  private changePasswordUser = new Subject<IChangePasswordData>();
  private changePasswordUserSource$ = this.changePasswordUser.pipe(
    switchMap((data) => {
      return this.authService
        .changePassword(data)
        .pipe(catchError(() => of(null)));
    }),
    filter((x) => x !== null),
    tap(() => {
      this.closePasswordDialog.next();
    }),
  );

  private getUser$ = merge(this.authenticate$, this.authenticateToken).pipe(
    delay(10),
    switchMap(() =>
      this.authService.getUser().pipe(catchError(() => of(null))),
    ),
  );

  private logoutSource = new Subject<void>();

  public authenticated$ = toObservable(this.isAuthenticated).pipe(
    filter((x) => x),
  );

  constructor() {
    this.authenticateSource.pipe(takeUntilDestroyed()).subscribe(() => {
      this.authUserState.update((state) => {
        return {
          ...state,
          processing: true,
        };
      });
    });

    this.authenticate$.pipe(takeUntilDestroyed()).subscribe((res) => {
      if (res !== null) {
        this.authUserState.update((state) => {
          return {
            ...state,
            token: res.token,
            isAuthenticated: true,
          };
        });
      }

      this.authUserState.update((state) => {
        return {
          ...state,
          processing: false,
        };
      });
    });

    this.getUser$.pipe(takeUntilDestroyed()).subscribe((res) => {
      if (res === null) {
        this.authUserState.update((state) => {
          return {
            ...state,
            processing: false,
          };
        });

        return;
      }

      this.setUser(res);
    });

    this.logoutSource.pipe(takeUntilDestroyed()).subscribe(() => {
      this.authUserState.update((state) => {
        return {
          ...state,
          user: null,
          token: null,
          isAuthenticated: false,
        };
      });
    });

    this.changePasswordAdminSource$.pipe(takeUntilDestroyed()).subscribe();
    this.changePasswordUserSource$.pipe(takeUntilDestroyed()).subscribe();

    const auth_token = localStorage.getItem(TOKEN_KEY);
    if (auth_token) {
      this.setToken(auth_token);
      this.authenticateToken.next('auth_token');
    }

    effect(() => {
      const user = this.user();

      if (user === null) return;

      let route = 'cms';

      if (localStorage.getItem('active_cms_link')) {
        route = localStorage.getItem('active_cms_link')!;
      }

      this.router.navigateByUrl(route);
    });

    effect(() => {
      const token = this.token();

      if (token === null) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.clear();

        this.router.navigate(['auth']);
        return;
      }

      localStorage.setItem(TOKEN_KEY, token);
    });
  }

  login(data: IAuthenticateData) {
    this.authenticateSource.next(data);
  }

  logout() {
    this.logoutSource.next();
  }

  public changePassword(data: IChangePasswordData) {
    this.changePasswordUser.next(data);
  }

  public changePasswordAdmin(data: IChangePasswordAdminData) {
    this._changePasswordAdmin.next(data);
  }

  private setUser(user: User) {
    this.authUserState.update((state) => {
      return {
        ...state,
        user,
        isAuthenticated: true,
        processing: false,
      };
    });
  }

  private setToken(token: string) {
    this.authUserState.update((state) => {
      return {
        ...state,
        token,
        isAuthenticated: true,
        processing: false,
      };
    });
  }
}

import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IColumn } from '../../../components/features/expandable-table/models/table.model';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsApiService {
  private http: HttpClient = inject(HttpClient);
  private readonly apiUrl = environment.apiUrl;

  getUserSettings(userEchoId: string) {
    return this.http.get<IUserSettings[]>(
      `${this.apiUrl}/api/v1/user/system-settings`,
      {
        params: { userEchoId },
      },
    );
  }

  updateUserSettings(body: IUpdateUserSettingsBody) {
    return this.http.put<IUserSettings>(
      `${this.apiUrl}/api/v1/user/system-settings`,
      body,
    );
  }
}

export interface IUpdateUserSettingsBody {
  key: string;
  userEchoId: string;
  data: {
    columns?: IColumn[];
    itemsPerPage?: string;
  };
}

export interface IUserSettings {
  id: string;
  key: string;
  userEchoId: string;
  data: {
    columns: IColumn[];
    itemsPerPage: string;
  };
}

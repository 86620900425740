import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthUserService } from '../services/auth-user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private authUserService: AuthUserService,
    private toastrService: ToastrService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.authUserService.token()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authUserService.token()}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            if (!this.authUserService.isAuthenticated()) break;
            this.toastrService.error(
              'Your session has expired. You will be redirected to the login page in 3 second.',
            );
            setTimeout(() => {
              this.authUserService.logout();
            }, 3000);
            break;
        }
        return throwError(() => error);
      }),
    );
  }
}
